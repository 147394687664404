import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import EndulaLogo from "../images/ENDULA LOGO.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import '../Css/Headermedia.css';
import '../Css/Header.css';

function Header() {
  const location = useLocation();
  const [showWhoWeAre, setShowWhoWeAre] = useState(false);
  const [showProducts, setShowProducts] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState('');
  const [scrolled, setScrolled] = useState(false);

  const handleSelect = (eventKey) => {
    setActiveDropdown(eventKey);
  };

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 10;
      if (isScrolled !== scrolled) {
        setScrolled(isScrolled);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [scrolled]);

  const isActive = (path) => location.pathname === path ? 'custom-active' : '';

  return (
    <Navbar expand="lg" fixed="top" className={`bg-Nav ${scrolled ? 'scrolled' : ''}`}>
      <Container>
        <Navbar.Brand as={Link} to="/" className='Logo-image'>
          <img src={EndulaLogo} alt='Endula Infra Logo' />
          {/* <div className='side-line'></div>
          <div className='text-white'>
            <p className='query'>Have any question ?</p>
            <div className='enquiry'>
              <FontAwesomeIcon icon={faPhone} className='phone-icon'/> 000 123 456 789
            </div>
          </div> */}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto menus">
            <Nav.Link as={Link} to="/" className={isActive('/')}>Home</Nav.Link>
            <Nav.Link as={Link} to="/AboutUs" className={isActive('/AboutUs')}>About Us</Nav.Link>
            <Nav.Link as={Link} to="/Aggregates"  className={isActive('/Aggregates')}>Aggregates</Nav.Link>
            <Nav.Link as={Link} to="/redy-mix-concrete"  className={isActive('/redy-mix-concrete')}>RMC</Nav.Link>
            <Nav.Link as={Link} to="/services" className={isActive('/services')}>Quality</Nav.Link>
            {/* <NavDropdown
              title="Products"
              id="basic-nav-dropdown"
              show={showProducts}
              onMouseEnter={() => setShowProducts(true)}
              onMouseLeave={() => setShowProducts(false)}
              className={`dropdown ${activeDropdown.startsWith('products-') ? 'active' : ''}`}
              onSelect={handleSelect}
            >
              <div className={`dropdown_menu--animated ${showProducts ? 'dropdown_menu-6' : ''}`}>
                <NavDropdown.Item eventKey="products-action-1" as={Link}  to="/sand">EndulaSand</NavDropdown.Item>
                <NavDropdown.Item eventKey="products-action-2" as={Link}  to="/Aggregates">Aggregates</NavDropdown.Item>
                <NavDropdown.Item eventKey="products-action-3" as={Link}  to="/plast">EndulaPlast</NavDropdown.Item>
                <NavDropdown.Item eventKey="products-action-4" as={Link}  to="/redy-mix-concrete">Ready mix Concrete</NavDropdown.Item>
              </div>
            </NavDropdown> */}

            <Nav.Link as={Link} to="/Process"  className={isActive('/Process')}>Process</Nav.Link>
            <Nav.Link as={Link} to=""  className={isActive('/Clients')}>Clients</Nav.Link>
            <Nav.Link as={Link} to="/contactUs"  className={isActive('/contactUs')}>Contact us</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
