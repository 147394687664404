
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import AOS from 'aos';
import 'aos/dist/aos.css';
import React,{useEffect} from 'react'
import { useNavigate } from 'react-router-dom';
import '../Css/ButtonAnimation.css'
import ButtonBigDark from './ButtonAnimation';
export default function SectionOne() {
  const navigate = useNavigate();
    useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);
  const handleMoreAboutUsClick = () => {
    navigate('/AboutUs');
  };
  return (
 <div className='SectionOne'>
     <div className='container-fluid'>
      <div className="row mt-5 mb-5 ">
        <div className='col-lg-6' data-aos="fade-right">
          <div className='whyEndula'>
            <h3>Why Choose Us</h3>
            <h2 className='mt-3 mb-3'>Experience ensures quality and reliability.</h2>
            <p>Our focus is clear to provide best-quality construction materials that stand the test of time. From sand and aggregates to plast and ready mix concrete, we strive to meet the diverse needs of builders and contractors across various projects. Our commitment to simplicity lies in our dedication to delivering reliable products, every time.</p>
            <ul>
              <li><FontAwesomeIcon icon={faCircleCheck} />Experience ensures quality and reliability.</li>
              <li><FontAwesomeIcon icon={faCircleCheck} /> Dedication to delivering reliable products every time.</li>
              <li><FontAwesomeIcon icon={faCircleCheck} /> Best-quality construction materials for diverse needs.</li>
            </ul>
            {/* <button onClick={handleMoreAboutUsClick}>More About Us</button> */}
        <button className='d-flex button-animation mt-5' onClick={handleMoreAboutUsClick}>
        <ButtonBigDark />
        <div className="side-text align-items-center justify-content-center d-flex"> <h4>Learn more<br/>about us</h4></div>
        </button>
          </div>
        </div>
        <div className='col-lg-6 three-images' data-aos="fade-up">
          <div className='row Images-sec'>
            <div className='col-lg-4 Images-sec-1'>
              <img src="https://www.buildlux.oxacor.com/wp-content/uploads/rooftops-768x512.jpg" />
              </div>
            <div className='col-lg-4 Images-sec-2'>
              <img src="https://www.shutterstock.com/shutterstock/photos/1141301900/display_1500/stock-photo-happy-builders-in-reflective-vest-and-hard-hat-pointing-at-building-house-1141301900.jpg" />
              </div>
            <div className='col-lg-4 Images-sec-3'>
              <img src="https://www.buildlux.oxacor.com/wp-content/uploads/office-buildings-windows-glass-architecture-facade-design-with-reflection-768x512.jpg" />
            </div>
          </div>
        </div>
      </div>
    </div>

 </div>
  )
}
