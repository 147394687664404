import React , {useEffect} from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';
import CountUp, { useCountUp } from 'react-countup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faHandPointRight, faHouse, faPaintRoller, faSquareCheck, faUser } from '@fortawesome/free-solid-svg-icons';
import VisibilitySensor from 'react-visibility-sensor';
import { faStar as solidStar } from '@fortawesome/free-solid-svg-icons';
import Rating from "react-rating";
import Card from 'react-bootstrap/Card';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import profilePic from '../images/profile.png';
import profileWomen from '../images/woman.png';
import { faComment } from '@fortawesome/free-solid-svg-icons/faComment';

export default function SectionThree() {
    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 3
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };
    useEffect(() => {
        AOS.init({
          duration: 1000,
        });
      }, []);
    return (
        <div>
            <div className='sectionThree'>
                <div className='container '>
                    <div className='sec-3-content'>
                        <h4>OUR SERVICES</h4>
                        <h2>YOUR SATISFACTION <br />
                            IS OUR CONCERN</h2>
                    </div>
                    <div className='d-flex'>
                        <div className='icon-content-11 mt-5'>
                            <div className='icon-content1'>
                                <h5>SAND PRODUCT<FontAwesomeIcon icon={faHouse} /></h5>
                                <p>Enhanced cohesion and durability facilitated by uniform particle shapes</p>
                            </div>
                            <div className='icon-content2'>
                                <h5>AGGREGATES PRODUCT<FontAwesomeIcon icon={faHouse} /></h5>
                                <p>Sed tristique dui ut nunc cursus, ut convallis neque vehicula. Sed ligula odio</p>
                            </div>
                        </div>
                        <div className='standing-man'>
                            <img src="https://tykit.rometheme.pro/concrete/wp-content/uploads/sites/19/2021/07/portrait-of-young-bearded-man-construction-worker-UV4HCMV.png" />
                        </div>
                        <div className='icon-content22'>
                            <div className='icon-content3'>
                                <h5><FontAwesomeIcon icon={faPaintRoller} />PLAST PRODUCT</h5>
                                <p>Sed tristique dui ut nunc cursus, ut convallis neque vehicula. Sed ligula odio</p>
                            </div>
                            <div className='icon-content4'>
                                <h5><FontAwesomeIcon icon={faPaintRoller} />READY MIX CONCRETE </h5>
                                <p>Sed tristique dui ut nunc cursus, ut convallis neque vehicula. Sed ligula odio</p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className='sec-3-content-sec-2'>
                    <div className='container'>
                        <div className='text-white row projects'>
                            <div className='col-lg-3'>
                                <VisibilitySensor partialVisibility>
                                    {({ isVisible }) => (
                                        <div>
                                            <span>
                                                {isVisible ? <CountUp end={214} /> : null}
                                            </span>
                                            <p>COMPLETED PROJECTS</p>
                                        </div>
                                    )}
                                </VisibilitySensor>
                            </div>
                            <div className='col-lg-3 client'>
                                <VisibilitySensor partialVisibility>
                                    {({ isVisible }) => (
                                        <div>
                                            <span>
                                                {isVisible ? <CountUp end={207} /> : null}
                                            </span>
                                            <p>HAPPY CLIENT</p>
                                        </div>
                                    )}
                                </VisibilitySensor>
                            </div>
                            <div className='col-lg-3 Team'>
                                <VisibilitySensor partialVisibility>
                                    {({ isVisible }) => (
                                        <div>
                                            <span>
                                                {isVisible ? <CountUp end={98} /> : null}
                                            </span>
                                            <p>PROFESSIONAL TEAM</p>
                                        </div>
                                    )}
                                </VisibilitySensor>
                            </div>
                            <div className='col-lg-3'>
                                <VisibilitySensor partialVisibility>
                                    {({ isVisible }) => (
                                        <div>
                                            <span>
                                                {isVisible ? <CountUp end={124} /> : null}
                                            </span>
                                            <p>AWARDS WINNING</p>
                                        </div>
                                    )}
                                </VisibilitySensor>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
            <div className='sec-3-content-sec-3'>
                <div className='container'>
                    <div className='row '>
                        <div className='col-lg-6 quality-content'>
                            <h4>QUALITY HAS PROVEN</h4>
                            <h2 className='aligned-text'>
                                <span className='first-line'>OUR CREDIBILITY</span><br />
                                <span className='second-line'>HAS BEEN PROVEN</span>
                            </h2>
                        </div>
                        <div className='col-lg-6 quality-content'>
                            <p>Phasellus sit amet consectetur mauris. Donec sollicitudin magna dolor, non sodales augue commodo vel. Fusce non velit dapibus odio maximus sollicitudin ac non orci. Aliquam nisi est, finibus vitae diam vel, efficitur tincidunt mauris. </p>
                            <div className='row'>
                                <div className='col-lg-6'>
                                    <p><FontAwesomeIcon icon={faSquareCheck} /> Material Quality Control</p>
                                    <p><FontAwesomeIcon icon={faSquareCheck} /> 100% satisfaction guarantee</p>
                                    <p><FontAwesomeIcon icon={faSquareCheck} /> Highly Professional Staff</p>
                                </div>
                                <div className='col-lg-6'>
                                    <p><FontAwesomeIcon icon={faSquareCheck} /> Material Quality Control</p>
                                    <p><FontAwesomeIcon icon={faSquareCheck} /> 100% satisfaction guarantee</p>
                                    <p><FontAwesomeIcon icon={faSquareCheck} /> Highly Professional Staff</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className=' testmonials mb-5'>
                <Carousel responsive={responsive} className='container'>
                    <Card.Body className='cards' data-aos="fade-up">
                        <Rating
                            initialRating={5}
                            readonly
                            fullSymbol={<FontAwesomeIcon icon={solidStar} />}
                        />
                        <p className='mt-4'>Phasellus sit amet consectetur mauris. Donec sollicitudin magna dolor,</p>
                        <div className='d-flex profile'>
                            <img src={profilePic} alt="Profile" />
                            <div>
                                <h6>Elsa Kardinal</h6>
                                <p>manager</p>
                            </div>
                        </div>
                    </Card.Body>
                    <Card.Body className='cards' data-aos="fade-up">
                        <Rating
                            initialRating={5}
                            readonly
                            fullSymbol={<FontAwesomeIcon icon={solidStar} />}
                        />
                        <p className='mt-4'>Phasellus sit amet consectetur mauris. Donec sollicitudin magna dolor,</p>
                        <div className='d-flex profile'>
                            <img src={profileWomen} alt="Profile" />
                            <div>
                                <h6>Elsa Kardinal</h6>
                                <p>manager</p>
                            </div>
                        </div>
                    </Card.Body>
                    <Card.Body className='cards' data-aos="fade-up">
                        <Rating
                            initialRating={5}
                            readonly
                            fullSymbol={<FontAwesomeIcon icon={solidStar} />}
                        />
                        <p className='mt-4'>Phasellus sit amet consectetur mauris. Donec sollicitudin magna dolor,</p>
                        <div className='d-flex profile'>
                            <img src={profilePic} alt="Profile" />
                            <div>
                                <h6>Elsa Kardinal</h6>
                                <p>manager</p>
                            </div>
                        </div>
                    </Card.Body>
                    <Card.Body className='cards' data-aos="fade-up">
                        <Rating
                            initialRating={5}
                            readonly
                            fullSymbol={<FontAwesomeIcon icon={solidStar} />}
                        />
                        <p className='mt-4'>Phasellus sit amet consectetur mauris. Donec sollicitudin magna dolor,</p>
                        <div className='d-flex profile'>
                            <img src={profilePic} alt="Profile" />
                            <div>
                                <h6>Elsa Kardinal</h6>
                                <p>manager</p>
                            </div>
                        </div>
                    </Card.Body>
                    <Card.Body className='cards' data-aos="fade-up">
                        <Rating
                            initialRating={5}
                            readonly
                            fullSymbol={<FontAwesomeIcon icon={solidStar} />}
                        />
                        <p className='mt-4'>Phasellus sit amet consectetur mauris. Donec sollicitudin magna dolor,</p>
                        <div className='d-flex profile'>
                            <img src={profileWomen} alt="Profile" />
                            <div>
                                <h6>Elsa Kardinal</h6>
                                <p>manager</p>
                            </div>
                        </div>
                    </Card.Body>
                    <Card.Body className='cards' data-aos="fade-up">
                        <Rating
                            initialRating={5}
                            readonly
                            fullSymbol={<FontAwesomeIcon icon={solidStar} />}
                        />
                        <p className='mt-4'>Phasellus sit amet consectetur mauris. Donec sollicitudin magna dolor,</p>
                        <div className='d-flex profile'>
                            <img src={profilePic} alt="Profile" />
                            <div>
                                <h6>Elsa Kardinal</h6>
                                <p>manager</p>
                            </div>
                        </div>
                    </Card.Body>
                </Carousel>
                <div className='container'>
                    <div className='row  contact-us'>
                        <div className='col-lg-6 contact-image'>
                            <img src="https://tykit.rometheme.pro/concrete/wp-content/uploads/sites/19/2021/07/structural-engineer-and-architect-dressed-in-shirt-WZ4R8U7.jpg" />
                        </div>
                        <div className='col-lg-6 contact-us-form' data-aos="fade-left">
                            <h2>Request <span>A Quote?</span></h2>
                            <p>GOT A QUESTION?</p>
                            <form>
                                <div className='name-field d-flex'>
                                <FontAwesomeIcon icon={faUser} /> 
                                <input type="text" placeholder="Name" required/>
                                <FontAwesomeIcon icon={faEnvelope} />
                                <input type="email" placeholder="E-mail" required/>
                                </div>
                                <div className='subject-field'>
                                    <FontAwesomeIcon icon={faHandPointRight} /> <input type="text" placeholder="Subject" required/>
                                </div>
                                <div className='message-field'>
                                <FontAwesomeIcon icon={faComment}/>
                            
                                    <textarea placeholder="Message" required></textarea>
                                </div>
                                <div className='submit'>
                                    <input type="submit" value="Send a message" />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
