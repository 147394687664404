import React, { useEffect, useRef, useState } from 'react';
import 'aos/dist/aos.css';
import AOS from 'aos';
import '../Css/sand.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Videoframe from '../images/videoframe_0.png'
import Card from 'react-bootstrap/Card';
import shape1 from '../images/shape1.svg';
import shape2 from '../images/shape2.svg';
export default function Endulasand() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef(null);

  useEffect(() => {
    AOS.init({
      duration: 1000,
    });

    const handlePlay = () => {
      setIsPlaying(true);
    };

    const handlePause = () => {
      setIsPlaying(false);
    };

    const handleEnded = () => {
      setIsPlaying(false);
    };

    const videoElement = videoRef.current;

    videoElement.addEventListener('play', handlePlay);
    videoElement.addEventListener('pause', handlePause);
    videoElement.addEventListener('ended', handleEnded);

    return () => {
      videoElement.removeEventListener('play', handlePlay);
      videoElement.removeEventListener('pause', handlePause);
      videoElement.removeEventListener('ended', handleEnded);
    };
  }, []);

  const handleVideoPlayPause = () => {
    const videoElement = videoRef.current;
    if (videoElement.paused) {
      videoElement.play();
    } else {
      videoElement.pause();
    }
  };
  const [advantages, setAdvantages] = useState([
    { title: "Enhanced cohesion and durability facilitated by uniform particle shapes" },
    { title: "Optimal gradation for superior compressive strength and structural integrity" },
    { title: "Balanced physical and chemical properties ensuring longevity of structures" },
    { title: "Consistent quality maintained through controlled production processes" },
    { title: "Absence of harmful materials minimizing wastage and maximizing cost-effectiveness" },
    { title: "Ideal amount of fine particles helps reduce concrete issues like segregations and gaps." },
    { title: "Year-round availability ensuring timely execution of construction projects" }
  ]);


  return (

    <div>
      <div className='Sand-heading-banner'>
        <h1 className='container'>Eco Sand</h1>
      </div>
      <div className='d-flex'>
        <img src={shape1} data-aos="fade-down" />
        <Container className='mt-5 mb-5 eco-content'>
          <Row className='eco-sand'>
            <Col lg={6} className='eco-sand-content'>
              {/* <h2>Eco Sand</h2> */}
              <p>Endula Infra introduces 'Eco Sand', revolutionizing the construction industry with a sustainable alternative to natural resources. Developed as a cost-effective solution, Eco Sand preserves our environment while offering superior performance.</p>
              <p>Eco Sand is manufactured to replicate the natural formation process of sand, ensuring uniformity in particle shapes and gradation. Utilizing advanced rock-hit-rock crushing techniques and state-of-the-art machinery, Endula Infra's manufacturing facilities employ a three-stage configuration comprising a Jaw Crusher, Cone Crusher, and Vertical Shaft Impactor (VSI) to achieve sand consistency unparalleled in the industry.</p>
              {/* <button>Learn More</button> */}
            </Col>
            <Col lg={6}>
              <div className='d-flex small-images'>
                <img src="https://www.shutterstock.com/shutterstock/photos/50610706/display_1500/stock-photo-happy-construction-workers-wearing-helmets-and-working-teamwork-concepts-50610706.jpg" data-aos="zoom-in" />
                <img src="https://www.shutterstock.com/shutterstock/photos/2023551878/display_1500/stock-photo-a-stopping-yellow-excavator-at-stunning-fluffy-clouds-2023551878.jpg" data-aos="zoom-in" />
              </div>
              <div className='big-image'>
                <img src="https://www.shutterstock.com/shutterstock/photos/2417329857/display_1500/stock-photo-drone-photography-of-excavator-moving-dirt-in-a-construction-site-during-autumn-cloudy-day-2417329857.jpg" data-aos="zoom-in-up" />
              </div>
            </Col>
          </Row>
        </Container>
        <img src={shape2} data-aos="fade-up" />
      </div>
      <section className="HomeVideoSection relative mt-5 mb-4">
        <div className="HomeVideoOuter">
          <div className="wrapper">
            <div className="HomeVideoInner">
              <div className="Video-playbtn" onClick={handleVideoPlayPause}>
                <svg
                  id="Component_16_9"
                  data-name="Component 16 – 9"
                  xmlns="http://www.w3.org/2000/svg"
                  width="15"
                  height="17"
                  viewBox="0 0 15 17"
                >
                  <path
                    id="Polygon_2"
                    data-name="Polygon 2"
                    d="M8.5,0,17,15H0Z"
                    transform="translate(15) rotate(90)"
                    fill="#fff"
                  ></path>
                </svg>
              </div>
              <div className="StoryVideo">
                <video
                  id="rightVideo"
                  ref={videoRef}
                  playsInline
                  controls
                  poster={Videoframe}
                >
                  <source
                    type="video/mp4"
                    src="https://www.shutterstock.com/shutterstock/videos/1111090565/preview/stock-footage-construction-site-on-a-hot-sunny-day-industrial-excavator-digging-sand-to-lay-foundation-for.webm"
                  />
                </video>
              </div>
              <div
                className={`HomeVideoTitleSection ${isPlaying ? 'animate_it' : 'animate_reverse'}`}
              >
                <div className="SectionTitle">
                  <h2>
                    Drive a Green Evolution in Construction for Tomorrow.
                  </h2>
                </div>
                <div className="SectionContent">
                  <p>
                    Eco Sand emerges as an eco-friendly solution, addressing the critical need for sustainable construction materials. As a perfect substitute for rapidly depleting river sand, Eco Sand plays a crucial role in preserving groundwater levels and safeguarding the environment.
                    With a particle size range of 0-4.75 mm, Eco Sand caters to diverse concrete preparations across all construction segments, including independent houses, builders, RMC plants, concrete batching plants, and infrastructure projects.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Container className='mb-5 sand-benefits'>
        <h3 className='text-center mt-5 '> Advantages of Eco SAND</h3>
        <hr className="grunge-underline"></hr>
        <Row className='advantages-sand'>
          {advantages.map((advantage, index) => (
            <Col lg={3} key={index} className='mx-auto mt-5'>
              <Card style={{
                backgroundColor: index % 2 === 0 ? '#fcceb2' : '#303748',
                color: index % 2 === 0 ? 'black' : 'white'
              }}>
                <Card.Body>{advantage.title}</Card.Body>
              </Card>

            </Col>
          ))}
        </Row>
      </Container>


    </div>
  )
}
