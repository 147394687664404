import React, { useEffect, useRef, useState } from 'react';
import '../Css/rmc.css';
import { Col, Container, Row } from 'react-bootstrap';
import 'aos/dist/aos.css';
import AOS from 'aos';
import Videoframe from '../images/videoframe_1960.png'
import Accordion from 'react-bootstrap/Accordion';
export default function Rmc() {
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef(null);

  useEffect(() => {
    AOS.init({
      duration: 1000,
    });

    const handlePlay = () => {
      setIsPlaying(true);
    };

    const handlePause = () => {
      setIsPlaying(false);
    };

    const handleEnded = () => {
      setIsPlaying(false);
    };

    const videoElement = videoRef.current;

    videoElement.addEventListener('play', handlePlay);
    videoElement.addEventListener('pause', handlePause);
    videoElement.addEventListener('ended', handleEnded);

    return () => {
      videoElement.removeEventListener('play', handlePlay);
      videoElement.removeEventListener('pause', handlePause);
      videoElement.removeEventListener('ended', handleEnded);
    };
  }, []);

  const handleVideoPlayPause = () => {
    const videoElement = videoRef.current;
    if (videoElement.paused) {
      videoElement.play();
    } else {
      videoElement.pause();
    }
  };
  return (
    <div>
      <div className='Rmc-heading-banner'>
        <h1 className='container'>Ready Mix Concrete</h1>
      </div>
      <Container>
        <Row className='rmc-content'>
          <Col lg={6}>
            <p>Sand Plast, a revolutionary solution for brick working and plastering applications in construction. Sand Plast blends premium sand aggregates with advanced additives, carefully manufactured to improve workability, adhesion, and finish quality in plastering tasks. Our unwavering commitment to excellence motivates us to offer construction experts a superior plastering material that surpasses industry standards.</p>

          </Col>
          <Col lg={6}>
            <img src="https://www.shutterstock.com/shutterstock/photos/2307050647/display_1500/stock-photo-close-up-ready-mix-concrete-at-construction-site-2307050647.jpg" alt="rmc" />
          </Col>
        </Row>
      </Container>
      <section className="HomeVideoSection relative mt-5 mb-4">
        <div className="HomeVideoOuter">
          <div className="wrapper">
            <div className="HomeVideoInner">
              <div className="Video-playbtn" onClick={handleVideoPlayPause}>
                <svg
                  id="Component_16_9"
                  data-name="Component 16 – 9"
                  xmlns="http://www.w3.org/2000/svg"
                  width="15"
                  height="17"
                  viewBox="0 0 15 17"
                >
                  <path
                    id="Polygon_2"
                    data-name="Polygon 2"
                    d="M8.5,0,17,15H0Z"
                    transform="translate(15) rotate(90)"
                    fill="#fff"
                  ></path>
                </svg>
              </div>
              <div className="StoryVideo">
                <video
                  id="rightVideo"
                  ref={videoRef}
                  playsInline
                  controls
                  poster={Videoframe}
                >
                  <source
                    type="video/mp4"
                    src="https://www.shutterstock.com/shutterstock/videos/1107214365/preview/stock-footage-mixing-concrete-mixture-in-a-large-mixer-fps-slow-motion.webm"
                  />
                </video>
              </div>
              <div
                className={`HomeVideoTitleSection ${isPlaying ? 'animate_it' : 'animate_reverse'}`}
              >
                <div className="SectionTitle">
                  <h2>
                    Build the future with every ready mix concrete pour."
                  </h2>
                </div>
                <div className="SectionContent">
                  <p>From raw materials like aggregates and cementitious materials to additives. This vertical integration ensures the highest quality and reliability of our RMC products. With a robust infrastructure and dedicated professionals, Endula Infra has emerged as a leading supplier of RMC to industries in the construction sector. Our production capacity exceeds [supply number] cubic meters per month, allowing us to meet the diverse demands of our clients, whether it's for large-scale infrastructure projects, residential developments, or commercial complexes.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className='service-bottom'>
        <h2 className='text-center mt-5 advantages-heading'>Advantages of Endula Infra RMC</h2>
        <hr className="grunge-underline"></hr>
        <Container fluid className='Accordion-advantages'>
          <Row>
            <Col lg={6}> <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>Efficiency</Accordion.Header>
                <Accordion.Body>
                Ready-to-use RMC eliminates the need for on-site mixing, reducing construction time and labor costs.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>Reliability</Accordion.Header>
                <Accordion.Body>
                With in-house raw material production and advanced technology, Endula RMC is trusted by industries for its superior performance and durability.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion></Col>
            <Col lg={6}> <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>Optimized Strength</Accordion.Header>
                <Accordion.Body>
                With precise mix designs and quality ingredients, our ready mix concrete delivers optimal compressive strength and structural performance.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>Environmental Sustainability</Accordion.Header>
                <Accordion.Body>
                Endula ready mix concrete incorporates eco-friendly practices, reducing waste and environmental impact during production and placement.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion></Col>
          </Row>
        </Container>
      </div>
    </div>
  )
}
