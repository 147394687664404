import React, { useState, useEffect } from 'react';
import '../Css/ButtonAnimation.css'; // Ensure to create a CSS file for the styles
const ButtonBigDark = () => {
  const initialInkAnimationStyle = {
    transform: 'translate3d(0px, 0%, 0px) scale3d(0.8, 0.8, 1)',
    transformStyle: 'preserve-3d',
  };
  const initialInkSpriteStyle = [
    { transform: 'translate3d(0px, 6.25%, 0px) scale3d(1, 1, 1)', transformStyle: 'preserve-3d' },
    { transform: 'translate3d(0px, 0%, 0px) scale3d(1, 1, 1)', transformStyle: 'preserve-3d' },
    { transform: 'translate3d(0px, -12.5%, 0px) scale3d(1, 1, 1)', transformStyle: 'preserve-3d' },
    { transform: 'translate3d(0px, -25%, 0px) scale3d(1, 1, 1)', transformStyle: 'preserve-3d' },
    { transform: 'translate3d(0px, -43.75%, 0px) scale3d(1, 1, 1)', transformStyle: 'preserve-3d' },
    { transform: 'translate3d(0px, -62.5%, 0px) scale3d(1, 1, 1)', transformStyle: 'preserve-3d' },
    { transform: 'translate3d(0px, -81.25%, 0px) scale3d(1, 1, 1)', transformStyle: 'preserve-3d' },
    { transform: 'translate3d(0px, -93.75%, 0px) scale3d(1, 1, 1)', transformStyle: 'preserve-3d' },
  ];
  const [inkAnimationStyle, setInkAnimationStyle] = useState(initialInkAnimationStyle);
  const [inkSpriteStyle, setInkSpriteStyle] = useState(initialInkSpriteStyle[0]);
  const handleMouseEnter = () => {
    setInkAnimationStyle({
      transform: 'translate3d(0px, -4.448%, 0px) scale3d(0.9112, 0.9112, 1)',
      transformStyle: 'preserve-3d',
    });
    let index = 1;
    const intervalId = setInterval(() => {
      setInkSpriteStyle(initialInkSpriteStyle[index]);
      index++;
      if (index >= initialInkSpriteStyle.length) clearInterval(intervalId);
    }, 100); // Change the delay as per your need
  };
  const handleMouseLeave = () => {
    setInkAnimationStyle(initialInkAnimationStyle);
    let index = initialInkSpriteStyle.length - 1;
    const intervalId = setInterval(() => {
      setInkSpriteStyle(initialInkSpriteStyle[index]);
      index--;
      if (index < 0) clearInterval(intervalId);
    }, 100); // Change the delay as per your need
  };
  return (
    <div className="middle">
      <div className="button-big-dark" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        <div className="wrap-rotate-ink-animation--60-degrees">
          <div className="wrap-ink-animation-button">
            <div className="ink-animation-button" style={inkAnimationStyle}>
              <img
                src="https://assets-global.website-files.com/6554d6c2535ed9a9964be3a4/6554d6c2535ed9a9964be423_Sprite_Button.png"
                loading="eager"
                alt=""
                className="ink-sprite"
                style={inkSpriteStyle}
              />
            </div>
          </div>
        </div>
        <img
          src="https://assets-global.website-files.com/6554d6c2535ed9a9964be3a4/6554d6c2535ed9a9964be4a3_Photo%20Button%20Splotch%20Left%20Dark.png"
          loading="lazy"
          alt=""
          className="img-button-splotch-left"
        />
        <div className="arrow-diagonal inline-svg w-embed">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
            <path
              d="M1,1H15V15"
              fill="none"
              stroke="currentColor"
              strokeMiterlimit="10"
              strokeWidth="2"
            />
            <line
              x1="0.78"
              y1="15.22"
              x2="15"
              y2="1"
              fill="none"
              stroke="currentColor"
              strokeMiterlimit="10"
              strokeWidth="2"
            />
          </svg>
        </div>
      </div>
       
    </div>
  );
};
export default ButtonBigDark;