import React, { useEffect, useRef, useState } from 'react';
import 'aos/dist/aos.css';
import AOS from 'aos';
import '../Css/aggregates.css'
import Videoframe from '../images/image.png'
import { Col, Container, Row } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
export default function EndulaAggregates() {
    const [isPlaying, setIsPlaying] = useState(false);
    const videoRef = useRef(null);

    useEffect(() => {
        AOS.init({
            duration: 1000,
        });

        const handlePlay = () => {
            setIsPlaying(true);
        };

        const handlePause = () => {
            setIsPlaying(false);
        };

        const handleEnded = () => {
            setIsPlaying(false);
        };

        const videoElement = videoRef.current;

        videoElement.addEventListener('play', handlePlay);
        videoElement.addEventListener('pause', handlePause);
        videoElement.addEventListener('ended', handleEnded);

        return () => {
            videoElement.removeEventListener('play', handlePlay);
            videoElement.removeEventListener('pause', handlePause);
            videoElement.removeEventListener('ended', handleEnded);
        };
    }, []);

    const handleVideoPlayPause = () => {
        const videoElement = videoRef.current;
        if (videoElement.paused) {
            videoElement.play();
        } else {
            videoElement.pause();
        }
    };
    return (
        <div>
            <div className='Aggregates-heading-banner'>
                <h1 className='container'>Aggregates</h1>
            </div>
            <Container className='aggregates-content'>
                <Row>
                    <Col lg={6}>
                        <p>Endula Infra has been at the forefront of the construction materials industry, specializing in the production of high-quality aggregates. Starting with a manufacturing unit in Hyderabad, we have expanded our operations in [    branch places    ] to meet the growing demands of the industry. Our commitment to superior standards and competitive pricing has made us a trusted name in aggregates and rock sand production. Our leading edge facility in Hyderabad is equipped with advanced machinery and technology to produce a wide range of aggregates that meet the highest industry standards.</p>
                    </Col>
                    <Col lg={6} className='aggregates-image'>
                        <img src="https://www.shutterstock.com/shutterstock/photos/1656663901/display_1500/stock-photo-recycled-concrete-aggregate-rca-which-is-produced-by-crushing-concrete-reclaimed-from-concrete-1656663901.jpg" alt="" />
                    </Col>
                </Row>
            </Container>
            <div className="our-range-aggregates">
                <h2 className='text-center'>Our Product Range</h2>
                <hr className="grunge-underline"></hr>
                <Container>
                    <Row>
                        <Col lg={6}>
                            <p>
                                At Endula Infra, we offer a comprehensive selection of aggregates to cater to diverse construction needs. Our 10mm metal is perfect for concrete production, road construction, and creating smooth pathways and driveways. This aggregate provides excellent binding properties and high durability, ensuring reliable performance.
                            </p>
                            <p>The 20mm metal aggregate is a versatile choice, suitable for forming the core layer in road works and large-scale concrete applications. It offers superior load-bearing capacity and enhanced stability, making it ideal for heavy-duty construction projects.</p>
                            <p>For more robust construction requirements, our 40mm metal aggregate is designed to provide high compressive strength and reliability. This aggregate is essential for foundation work and large-scale infrastructure projects, ensuring strong and durable foundations.</p>
                        </Col>
                        <Col lg={6}>
                            <p>
                                Our granular sub base (GSB) is crucial for constructing stable sub-layers in road construction. It provides excellent load distribution and enhances the longevity of road surfaces, making it a cost-effective solution for building roads and highways.
                            </p>
                            <p>We also offer a variety of sands to meet specific needs. Our plaster sand is finely graded for a smooth finish in plastering work, both internal and external. It is washed and sieved to ensure the highest quality, perfect for brickwork and block work. For more general construction applications, our washed sand is a versatile and clean option, ideal for concrete production, mortar, and landscaping. </p>

                        </Col>
                    </Row>
                </Container>
                <section className="HomeVideoSection relative mt-5 mb-4">
                    <div className="HomeVideoOuter">
                        <div className="wrapper">
                            <div className="HomeVideoInner">
                                <div className="Video-playbtn" onClick={handleVideoPlayPause}>
                                    <svg
                                        id="Component_16_9"
                                        data-name="Component 16 – 9"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="15"
                                        height="17"
                                        viewBox="0 0 15 17"
                                    >
                                        <path
                                            id="Polygon_2"
                                            data-name="Polygon 2"
                                            d="M8.5,0,17,15H0Z"
                                            transform="translate(15) rotate(90)"
                                            fill="#fff"
                                        ></path>
                                    </svg>
                                </div>
                                <div className="StoryVideo">
                                    <video
                                        id="rightVideo"
                                        ref={videoRef}
                                        playsInline
                                        controls
                                        poster={Videoframe}
                                    >
                                        <source
                                            type="video/mp4"
                                            src="https://www.shutterstock.com/shutterstock/videos/3390450129/preview/stock-footage-belt-conveyor-cold-elevator-and-hopper-to-supply-cold-aggregate-from-cold-bin-distribute-cold.webm"
                                        />
                                    </video>
                                </div>
                                <div
                                    className={`HomeVideoTitleSection ${isPlaying ? 'animate_it' : 'animate_reverse'}`}
                                >
                                    <div className="SectionTitle">
                                        <h2>
                                            Strength in every construction.
                                        </h2>
                                    </div>
                                    <div className="SectionContent">
                                        <p>Our washed plaster sand is meticulously cleaned and graded, making it suitable for high-end plastering and decorative finishes.</p>
                                        <p>Our crushed rock fine is a byproduct of the crushing process, providing a fine aggregate that serves as an excellent substitute for natural sand. It is used in making concrete, structural fills, and road bases, offering a cost-effective and environmentally friendly solution.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div className='service-bottom'>
                    <h2 className='text-center mt-5 advantages-heading'>Advantages of Endula Aggregates</h2>
                    <hr className="grunge-underline"></hr>
                    <Container fluid className='Accordion-advantages'>
                        <Row>
                            <Col lg={6}> <Accordion defaultActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Superior Load-Bearing Capacity</Accordion.Header>
                                    <Accordion.Body>
                                        Our metal aggregates, particularly the 20mm and 40mm sizes, offer excellent load-bearing capacity, making them ideal for heavy-duty construction work.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>Excellent Binding Properties</Accordion.Header>
                                    <Accordion.Body>
                                        Aggregates like our 10mm metal provide superior binding, ensuring strong adhesion in concrete mixes and enhanced structural integrity.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion></Col>
                            <Col lg={6}> <Accordion defaultActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Versatility</Accordion.Header>
                                    <Accordion.Body>
                                        From fine plaster sand to robust 40mm metal, our range of products caters to various construction needs, making Endula Infra a one-stop solution for all aggregate requirements.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>Environmental Responsibility</Accordion.Header>
                                    <Accordion.Body>
                                        Our crushed rock fine is an environmentally friendly alternative to natural sand, promoting sustainable construction practices.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion></Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </div>
    )
}
