import React from 'react'
import Header from './Header';
import BannerImage from './BannerImage';
import SectionOne from './SectionOne';
import SectionTwo from './SectionTwo';
import SectionThree from './SectionThree';
import Map from './Map';
import Footer from './Footer';
import BannerCarousel from './BannerCarousel';
import Clients from './Clients';
export default function HomePage() {
  return (
    <div>
          {/* <Header /> */}
          {/* <BannerImage /> */}
          <BannerCarousel />
          <SectionOne />
          <SectionTwo />
          <SectionThree />
          <Clients />
          {/* <Map /> */}
          {/* <Footer /> */}
    </div>
  )
}
