import React, { useState } from 'react';
import '../Css/process.css';
import { Col, Container, Row } from 'react-bootstrap';

export default function Process() {
    const [selectedSlide, setSelectedSlide] = useState("c1"); // Default to first slide

    const handleSlideChange = (event) => {
        setSelectedSlide(event.target.id);
    };
    return (
        <div>
            <div className='process'>
                <video autoPlay loop muted className="fullscreen-video">
                    <source src="https://www.shutterstock.com/shutterstock/videos/1088452525/preview/stock-footage-august-kupol-russia-a-komatsu-pc-excavator-loads-ore-into-a-komatsu-hd-dump.webm" type="video/webm" />
                    <source src="https://www.shutterstock.com/shutterstock/videos/1088452525/preview/stock-footage-august-kupol-russia-a-komatsu-pc-excavator-loads-ore-into-a-komatsu-hd-dump.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </div>
            <div className="process-content">
                <div className="text-center">
                    <h2>Quarrying Process at Endula Infra</h2>
                    <p>At Endula Infra, our quarrying process is designed to ensure the extraction of high-quality aggregates while prioritizing efficiency and safety.</p>
                </div>
                <Container className='quarrying-process'>
                    <Row>
                        <Col lg={6}>
                            <p>Endula infra plant consists of three stages where crushing takes place – primary,secondary and tertiary stage.
                                Granite rock is brought from quarries after drilling, blasting, segregation and transportation to the plant is fed in to the primary stage consisting of one jaw crusher that sizes down the granite to produce 150mm aggregate
                                This is then fed in to the secondary stage consisting of one jaw crusher/one cone crusher based on requirements to produce 40mm size aggregate.
                               
                            </p>
                        </Col>
                        <Col lg={6}>
                           <p> Which in turn is fed in to the tertiary stage consisting one vertical shaft impactor(VSI) with latest DFP technology that produces completely dust free sand and aggregate of the required gradation and shape. The plant works on the principle of continuous feed in closed stages and adopts techniques synonymous with nature’s process on river sand.</p>
                        </Col>
                    </Row>
                </Container>
                <div className="wrapper">
                    <div className="container">
                        {/* Card 1 */}
                        <input type="radio" name="slide" id="c1" checked={selectedSlide === "c1"} onChange={handleSlideChange} />
                        <label htmlFor="c1" className="card">
                            <div className="row">
                                <div className="icon">1</div>
                                <div className="description">
                                    <h4>Drilling and Blasting</h4>

                                </div>
                            </div>
                        </label>

                        {/* Card 2 */}
                        <input type="radio" name="slide" id="c2" checked={selectedSlide === "c2"} onChange={handleSlideChange} />
                        <label htmlFor="c2" className="card">
                            <div className="row">
                                <div className="icon">2</div>
                                <div className="description">
                                    <h4>Extraction and Transportation</h4>

                                </div>
                            </div>
                        </label>

                        {/* Card 3 */}
                        <input type="radio" name="slide" id="c3" checked={selectedSlide === "c3"} onChange={handleSlideChange} />
                        <label htmlFor="c3" className="card">
                            <div className="row">
                                <div className="icon">3</div>
                                <div className="description">
                                    <h4>Crushing Circuits</h4>

                                </div>
                            </div>
                        </label>

                        {/* Card 4 */}
                        <input type="radio" name="slide" id="c4" checked={selectedSlide === "c4"} onChange={handleSlideChange} />
                        <label htmlFor="c4" className="card">
                            <div className="row">
                                <div className="icon">4</div>
                                <div className="description">
                                    <h4>Plant Capacity</h4>

                                </div>
                            </div>
                        </label>
                    </div>
                </div>
            </div>
        </div>
    );
}

