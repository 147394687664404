import React, { useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../Css/service.css';
import 'animate.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Card from 'react-bootstrap/Card';
import AggregatesImage from '../images/aggregates.jpeg';
import AggregatesImage2 from '../images/p3XYERNVnDRDIqEGXSV2Q-transformed.jpeg';
import AggregatesImage3 from '../images/797Y1aWW4z7J7tt8aDGw_-transformed.jpeg';
import { Link } from 'react-router-dom';
export default function Services() {
  const [test, setTest] = useState([
    {
      imageUrl: "https://www.shutterstock.com/shutterstock/photos/1525414352/display_1500/stock-photo-laboratory-equipment-for-pulverizing-and-sieving-of-soil-samples-on-light-table-flat-lay-1525414352.jpg",
      label: "Sieve Analysis Test 1",
      description: "We conduct regular sieve analysis tests on Eco Sand, Aggregates 20mm, and 10mm to ascertain precise particle size distribution. Samples are collected daily from conveyor belts, stockpiles, and loaded trucks for thorough analysis."
    },
    {
      imageUrl: "https://www.shutterstock.com/shutterstock/photos/2025910373/display_1500/stock-photo-river-soil-composition-concept-woman-a-scientist-takes-a-sludge-sample-from-a-river-close-up-2025910373.jpg",
      label: "Silt Test (Fine material below 75 microns)",
      description: "Determining the silt test is vital to ensure the purity and suitability of our materials for various construction applications."
    },
    {
      imageUrl: "https://www.shutterstock.com/shutterstock/photos/1765735961/display_1500/stock-photo-worker-using-equipment-for-testing-laboratory-field-density-test-sand-cone-method-and-soil-density-1765735961.jpg",
      label: "DLBD & DRBD Test (Density Tests)",
      description: "Our DLBD (Dry Loose Bulk Density) and DRBD (Dry Rodded Bulk Density) tests evaluate the compactness and density of aggregates, crucial for structural integrity."
    },
    {
      imageUrl: "https://www.shutterstock.com/shutterstock/photos/1533863795/display_1500/stock-photo-laboratory-standard-test-method-for-clay-lumps-and-friable-particles-in-aggregates-in-construction-1533863795.jpg",
      label: "Aggregate Impact Value Test",
      description: "This test assesses the resistance of aggregates to sudden impacts, providing insights into their durability and strength."
    },
    {
      imageUrl: "https://www.shutterstock.com/shutterstock/photos/1831162729/display_1500/stock-photo-workers-pouring-concrete-with-a-cement-mixer-truck-road-construction-or-general-surface-repair-1831162729.jpg",
      label: "Aggregate Crushing Value Test",
      description: "We measure the crushing strength of aggregates to gauge their ability to withstand heavy loads and stress."
    },
    {
      imageUrl: "https://www.shutterstock.com/shutterstock/photos/1065031985/display_1500/stock-photo-prestressed-box-beam-concrete-placement-with-styrofoam-void-encasing-strand-and-epoxy-coated-rebar-1065031985.jpg",
      label: " Elongation Index Test",
      description: "These tests ensure that our aggregates meet the prescribed standards regarding shape and size, essential for optimal performance in construction."
    },
    {
      imageUrl: "https://www.shutterstock.com/shutterstock/photos/1765735961/display_1500/stock-photo-worker-using-equipment-for-testing-laboratory-field-density-test-sand-cone-method-and-soil-density-1765735961.jpg",
      label: "Water Absorption Test",
      description: "Water absorption testing is imperative to determine the porosity and moisture susceptibility of our materials."
    },
    {
      imageUrl: "https://www.shutterstock.com/shutterstock/photos/1491368666/display_1500/stock-photo-dirty-erlenmeyer-flasks-with-dark-brown-soil-texture-samples-suspended-in-a-coffee-like-liquid-1491368666.jpg",
      label: "Specific Gravity Test",
      description: "The specific gravity test helps in assessing the density of aggregates, aiding in precise mix designs and construction calculations."
    }
  ]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);
  return (
    <div>
      <div className='Services-banner'>
        <div className='container'>
          <Row>
            <Col lg={6}></Col>
            <Col lg={6} className='service-mainheadings'>
              <h3 className='animate__animated animate__backInDown'>Service with a commitment to excellence</h3>
              <p>At Endula Infra, we provide a full suite of services designed to meet all your construction material needs. Our comprehensive offerings include</p>
              <div className='d-flex service-buttons'>
                <button>See Our Products</button>
                <button>
                <Link to="/contactUs">
                  Contact Us
                </Link>
                </button>
              </div></Col>
          </Row>
        </div>
      </div>
      <div className="services-content">
        <Container>
          <Row className='First-row'>
            <h2 className='text-center mb-5'>Services</h2>
            <Col lg={6}>
              <div class="center-content"> <h3>Quarrying and Aggregate Supply</h3>
                <p>Our quarries and aggregate processing facilities ensure a steady supply of high-quality construction materials. From precision drilling and controlled blasting to meticulous processing and grading, we deliver aggregates of superior quality and consistency for your projects.</p></div>
            </Col>
            <Col lg="6">
              <div className='service-image-dots'>
                <div className='service-image'>
                  <img src={AggregatesImage} alt="the-wheel-loader-on-a-building-site" data-aos="fade-left" /></div>
              </div>
            </Col>
          </Row>
          <Row className='mt-5'>
            <Col lg="6">
              <div className='service-image-dots'>
                <div className='service-image'>
                  <img src={AggregatesImage2} alt="the-wheel-loader-on-a-building-site" data-aos="fade-left" /></div>
              </div>
            </Col>
            <Col lg={6}>
              <div class="center-content construction"><h3>Construction Material Supply</h3>
                <p className='para-2'>Endula Infra is your one-stop solution for all construction material needs. Whether it's aggregates, sand, RMC or specialty products, we provide a wide range of construction materials sourced from trusted suppliers to ensure reliability and performance.</p></div>
            </Col>
          </Row>
          <Row className='mt-5'>
            <Col lg={6}>
              <div className='center-content'> <h3>Manufactured Sand Production</h3>
                <p>As pioneers in the field, we offer manufactured sand as a sustainable and cost-effective alternative to natural river sand. This innovation helps conserve natural resources while providing a reliable construction material.</p></div>
            </Col>
            <Col lg="6">
              <div className='service-image-dots'>
                <div className='service-image'>
                  <img src={AggregatesImage3} alt="the-wheel-loader-on-a-building-site" data-aos="fade-left" /></div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className='text-center mt-5 mb-5 Quality-Tests'>
        <h2 className='mt-2 mb-3'>Quality Tests Performed</h2>
        <p>At Endula Infra, we ensure the highest standards of quality through rigorous testing procedures. Our quality control measures encompass a comprehensive range of tests to guarantee the excellence of our products.</p>
      </div>


      <div className="video-background-container">
        <video autoPlay loop muted className="video-background">
          <source src="https://www.shutterstock.com/shutterstock/videos/1052596025/preview/stock-footage-black-bucket-with-mortar-for-filling-the-floor-bucket-with-mortar-at-the-construction-site.webm" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="video-overlay"></div>
        <Container>
          <Row>
            {test.map((item, index) => (
              <Col lg={3} key={index} className='quality'>
                <Card style={{ height: "30vh" }} className='mt-4 card-hover'>
                  <Card.Body className="card-image-wrapper">
                    <Card.Img variant="top" src={item.imageUrl} />
                    <div className="card-title-overlay">
                      <Card.Title>{item.label}</Card.Title>
                    </div>
                    <div className="card-text-overlay">
                      <Card.Title>{item.label}</Card.Title>
                      <Card.Text>{item.description}</Card.Text>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </div>
  )
}
