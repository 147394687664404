import './App.css';
import './Css/Header.css';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import HomePage from './components/HomePage';
import AboutUs from './components/AboutUs';
import Header from './components/Header';
import Footer from './components/Footer';
import Endulasand from './components/Endulasand';
import ContactUs from './components/ContactUs';
// import Map from './components/Map';
import Services from './components/Services';
import Process from './components/Process';
import EndulaAggregates from './components/EndulaAggregates';
import Plast from './components/Plast';
import Rmc from './components/Rmc';
function App() {
  return (
    <div>
     <Router>
      <Header />
      <Routes>
        <Route path="/" element={<HomePage />} /> 
        <Route path="/AboutUs" element={<AboutUs />} />
        <Route path="/services" element={<Services />} />
        <Route path="/sand" element={<Endulasand />} />
        <Route path="/contactUs" element={<ContactUs />} />
        <Route path="/Process" element={<Process />} />
        <Route path="/Aggregates" element={<EndulaAggregates />} />
        <Route path="/plast" element={<Plast />} />
        <Route path="/redy-mix-concrete" element={<Rmc />} />
      </Routes>
      {/* <Map /> */}
      <Footer />
    </Router>
    </div>
  );
}

export default App;
