import React, { useState } from 'react';
import { Row, Col, Container, Button } from 'react-bootstrap';
import '../Css/Header.css';

export default function Clients() {
    const allClientLogos = [
        "AGS & JB PROPERTIES",
        "https://www.aparnaconstructions.com/sites/default/files/logo.png",
        "APR BUILDERS AND CONTRACTORS",
        "https://arkgroup.in/images/logo.png",
        "G R INFRACON PRIVATE LIMITED",
        "https://dyimg77.exportersindia.com/company_logo/1907128.jpg",
        "GOWRA ESTATES PRIVATE LIMITED",
        "GOWRA PALLADIUM PRIVATE LIMITED",
        "https://gowraventures.com/wp-content/uploads/2018/04/gowra-logo.png",
        "https://infra.market/assets/images/inframarket-logo.png?v=2",
        "https://inventaa.com/wp-content/uploads/2022/03/download-1-1-1.jpg",
        "JAIDHAR CONSTRUCTIONS",
        "https://jainconstructions.com/uploads/images/11652264896.png",
        "K M R ESTATES & BUILDERS PRIVATE LIMITED",
        "KALPATARU POWER TRANSMISSION LTD",
        "MY HOME INFRASTRUCTURES PVT LTD",
        "NCL INDUSTRIES LIMITED",
        "NJR CONSTRUCTIONS PRIVATE LIMITED",
        "NUVOCO VISTAS CORPORATION LTD",
        "OM SREE BUILDERS & DEVELOPERS",
        "QUBEX SILICON INDUSTRIES",
        "RAJA PUSHPA PROPERTIES PRIVATE LIMITED",
        "RAJAPUSHPA INFRA PRIVATE LIMITED",
        "SHAPOORJI PALLONJI AND COMPANY PRIVATE LIMITED",
        "SREEMAN CONSTRUCTIONS",
        "SRI LAKSHMI ENTERPRISES",
        "SRIAS LIFE SPACES LLP",
        "TELLAPUR TECHNOCITY PRIVATE LIMITED",
        "VEDANTA CONSTRUCTIONS"
    ];

    const itemsPerRow = 4; // Adjust based on your layout
    const rowsToShow = 3;
    const [showAll, setShowAll] = useState(false);

    const handleToggle = () => {
        setShowAll(!showAll);
    };

    const visibleLogos = showAll ? allClientLogos : allClientLogos.slice(0, rowsToShow * itemsPerRow);

    return (
        <div>
            <Container className='our-clients-section'>
                <Row className='text-center mb-4'>
                    <h2>Take a look At Our Clients</h2>
                </Row>
                <Row>
                    {visibleLogos.map((item, index) => (
                        <Col lg={3} md={6} sm={12} key={index}>
                            <div className='client-border mt-4'>
                                {item.startsWith('http') ? (
                                    <img src={item} alt={`Client ${index + 1}`} className='client-logo' />
                                ) : (
                                    <p>{item}</p>
                                )}
                            </div>
                        </Col>
                    ))}
                </Row>
                <Row className='text-center mt-4'>
                    <Col>
                        <Button onClick={handleToggle}>
                            {showAll ? 'View Less' : 'View More'}
                        </Button>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
