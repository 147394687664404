import React, { useState, useEffect } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import AOS from 'aos';
import 'aos/dist/aos.css';
import BannerVideo from '../images/crane-claw-loading-sand-into-conveyor-at-concrete-2023-11-27-04-57-55-utc.mov'
export default function BannerCarousel() {
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };
    useEffect(() => {
        AOS.init({
            duration: 1000,
        });
    }, []);
    return (
        <div>
            <Carousel activeIndex={index} onSelect={handleSelect} fade>
                <Carousel.Item>
                    <video autoPlay loop muted style={{ objectFit: "cover", height: "100vh", width: "100vw" }}>
                        <source src={BannerVideo} type="video/webm" />
                        <source src={BannerVideo} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                    <Carousel.Caption className="left-caption">
                        <div className="Tag-line1">
                            <h3 data-aos="fade-up" data-aos-duration="1000">30 Commercial Road Fratton</h3>
                            <div className="mask-wrap">
                                <div className="layer">
                                    Smart living with <br /> services
                                    <span className="char spacing">5</span>
                                    <span className="char">s</span>
                                    <span className="char">t</span>
                                    <span className="char">a</span>
                                    <span className="char">r</span>
                                    <span className="char">s</span>
                                </div>
                            </div>
                            <p className=''>Boost your future with expert solutions.</p>
                        </div>
                    </Carousel.Caption>
                </Carousel.Item>

                <Carousel.Item>
                    <img src="https://gaviaspreview.com/wp/aports/wp-content/uploads/2023/01/slider-2.jpg" alt="Second slide" />
                    <Carousel.Caption className="center-caption">
                        <div className="Tag-line2">
                            <div className='animate-tag2'>
                                <h3>Trust builds</h3>
                                <h3> our foundation</h3>
                            </div>
                            <p className='mt-5 tagpara'>Boost your future with expert solutions.</p>
                        </div>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img src="https://gaviaspreview.com/wp/aports/wp-content/uploads/2023/01/slider-3.jpg" alt="Third slide" />
                    <Carousel.Caption className="center-caption">
                        <div className='Tag-line'>
                            <div className="caption-content right-cube">
                                <div className='welcome'><h6>Strength you can rely on</h6></div>
                                <h3>Quality in every mix</h3>
                                <p>Mixing strength, shaping landscapes.</p>
                            </div>
                        </div>
                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel>
            <div className="carousel-indicators">
                <input type="radio" name="carousel" id="carousel-0" checked={index === 0} onChange={() => setIndex(0)} />
                <label htmlFor="carousel-0"></label>
                <input type="radio" name="carousel" id="carousel-1" checked={index === 1} onChange={() => setIndex(1)} />
                <label htmlFor="carousel-1"></label>
                <input type="radio" name="carousel" id="carousel-2" checked={index === 2} onChange={() => setIndex(2)} />
                <label htmlFor="carousel-2"></label>
            </div>
        </div>
    )
}
