import React, { useState, useEffect } from 'react';
import EndulaLogo from "../images/Logo.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faXTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faPlus, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
export default function Footer() {
  const [showButton, setShowButton] = useState(false);

  const handleScroll = () => {
    const currentScrollPos = window.pageYOffset;
    console.log('Current Scroll Position:', currentScrollPos);
    if (currentScrollPos > 0) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    // Call handleScroll initially to set the correct state based on the initial scroll position
    handleScroll();
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []); // Empty dependency array to run effect only once


  // Add scroll event listener when component mounts
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []); //
  return (
    <div className='Footer'>
      <div className='container'>
        <div className='row mb-5'>
          <div className='col-lg-3'>
            <div className='About-company'>
              <img src={EndulaLogo} alt='Endula-Logo' />
              <p>Duis euismod urna ac venenatis facilisis. Duis gravida quis orci et tempus. Donec tincidunt egestas mi, sit amet consectetur dolor placerat id.</p>
            </div>
          </div>
          <div className='col-lg-3'>
            <div className='Quick-links'>
              <h4>QUICK LINKS</h4>
              <ul>
                <li>
                  <Link to="/AboutUs">
                    <FontAwesomeIcon icon={faPlus} /> About Us
                  </Link>
                </li>
                <li>
                <Link to="/services"><FontAwesomeIcon icon={faPlus} />Services</Link>
                </li>
                <li><FontAwesomeIcon icon={faPlus} />Process</li>
                <li>
                  <Link to="/contactUs">
                <FontAwesomeIcon icon={faPlus} />Contact
                </Link></li>
              </ul>
            </div>
          </div>
          <div className='col-lg-3'>
            <div className='OTHERPAGE'>
              <h4>OTHER PAGE</h4>
              <ul>
                <li><FontAwesomeIcon icon={faPlus} />Privacy Policy</li>
                <li><FontAwesomeIcon icon={faPlus} />Term Of Service</li>
                <li><FontAwesomeIcon icon={faPlus} />Disclaimer</li>
                <li><FontAwesomeIcon icon={faPlus} />Credit</li>
              </ul>
            </div>
          </div>
          <div className='col-lg-3'>
            <div className='SocialMedia'>
              <h4>OUR SOCIAL MEDIA</h4>
              <p>Donec tincidunt egestas mi, sit amet consectetur.</p>
              <div className='socialmedia-icons d-flex'>
                <FontAwesomeIcon icon={faFacebook} />
                <FontAwesomeIcon icon={faXTwitter} />
                <FontAwesomeIcon icon={faYoutube} />
                <FontAwesomeIcon icon={faInstagram} />
              </div>
            </div>
          </div>
        </div>
        <p className='horizontal-rule' ></p>
        <p className='text-center'>Copyright &copy; 2024 Endula Infra. All Rights Reserved.</p>
      </div>
      <div className={`right-side ${showButton ? 'show' : ''}`} onClick={scrollToTop}>
        <FontAwesomeIcon icon={faChevronUp} />
      </div>

    </div>
  )
}
