import React, { useEffect } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import "../Css/AboutUs.css";
import AOS from 'aos';
import 'aos/dist/aos.css';
export default function AboutUs() {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className='heading-banner'>
        <h1 className='container'>About Us</h1>
      </div>
      <div className='container'>
        <div className='row mt-5 aboutus-endula'>
          <div className="col-12 col-lg-7">
            <div className="images-wrap-item img-left" >
              <img
                src="https://html.modernwebtemplates.com/precast/images/image-3-1-601x716.png"
                className="attachment-weldo-service-width size-weldo-service-width wp-post-image"
                loading="lazy"
                alt="Primary Image"
                data-aos="fade-up"
              />
              <img
                className="image-back"
                src="https://html.modernwebtemplates.com/precast/images/image-6.png"
                alt="Background Image"
                data-aos="fade-down"
              />
            </div>
          </div>
          <div className='col-12 col-lg-5 about-content ' data-aos="fade-left">
            <p><span className='bold-endula'>Endula Infra</span>, headquartered in Hyderabad and incorporated in 2011, echoes a similar commitment to excellence in every facet of its operations. Drawing from over 25 years of expertise in mining, quarrying, processing, and represents a dedication to delivering top-tier products. Since its inception, Endula has lead the production of essential construction materials like sand, aggregates, plast, and ready mix concrete, consistently setting new standards of quality and reliability.</p>
            <p><span className='bold-endula'>Endula Infra's</span> innovation extends to its pioneering efforts in manufacturing sand, echoing its dedication to sustainability and cost-effectiveness. Positioned as a viable alternative to dwindling(diminish) natural resources like river sand, Our products not only ensure environmental protection but also promote ethical and legal construction practices. The steadfast commitment to excellence has earned the trust and respect of industry leaders, including prominent figures in real estate and construction. With a focus on quality, sustainability, and customer satisfaction, Endula Infra stands as a beacon of progress and innovation in the construction materials sector.</p>
          </div>
        </div>
      </div>
      {/* <article class="main-container__marquee">
        <div class="main-container__marquee-track">
          <div class="main-container__marquee-items">
            <span class="main-container__marquee-item">
              WE BUILD DREAMS. LET'S START YOUR'S
            </span>
            <span class="main-container__marquee-item">
              WE BUILD DREAMS. LET'S START YOUR'S
            </span>
            <span class="main-container__marquee-item">
              WE BUILD DREAMS. LET'S START YOUR'S
            </span>
          </div>
          <div aria-hidden="true" class="main-container__marquee-items">
            <span class="main-container__marquee-item">
              WE BUILD DREAMS. LET'S START YOUR'S
            </span>
            <span class="main-container__marquee-item">
              WE BUILD DREAMS. LET'S START YOUR'S
            </span>
            <span class="main-container__marquee-item">
              WE BUILD DREAMS. LET'S START YOUR'S
            </span>
          </div>
        </div>
      </article> */}
      <div className='why-choose-about-us'>
        <div className="container-fluid">
          <div className='container text-center mt-5 why-choose-endula'>
            <h2>Why choose us</h2>
            <hr className="grunge-underline"></hr>
          </div>

          <Row className='justify-content-center '>
            <Col lg={4} className='first-row-point-one ' data-aos="fade-right">
              <Row>
                <Col lg={4} className='point-1-image'>
                  <div className='point-01'><h5>01</h5></div>
                  <img src="https://demo.xperthemes.com/constrox/wp-content/uploads/sites/2/2024/01/construction-industry-concept-150x150.jpg" alt="" />
                </Col>
                <Col lg={8}>
                  <h5>Proven Proficiency</h5>
                  <p>With decades of industry experience, Endula Infra brings unparalleled expertise to the supply of premium aggregates and RMC.</p>
                </Col>
              </Row>
            </Col>
            <Col lg={4} className='first-row-point-one ms-5' data-aos="fade-left">
              <Row>
                <Col lg={4} className='point-1-image'>
                  <div className='point-01'><h5>02</h5></div>
                  <img src="https://demo.xperthemes.com/constrox/wp-content/uploads/sites/2/2024/01/construction-industry-concept-150x150.jpg" alt="" />
                </Col>
                <Col lg={8}>
                  <h5>Holistic Solutions</h5>
                  <p>Endula Infra offers comprehensive aggregate and RMC services, meticulously tailored to meet the diverse needs of businesses, regardless of size.</p>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className='justify-content-center first-row mt-5'>
            <Col lg={4} className='first-row-point-one me-3' data-aos="fade-right">
              <Row>
                <Col lg={4} className='point-1-image'>
                  <div className='point-01'><h5>03</h5></div>
                  <img src="https://demo.xperthemes.com/constrox/wp-content/uploads/sites/2/2024/01/construction-industry-concept-150x150.jpg" alt="" />
                </Col>
                <Col lg={8}>
                  <h5>Innovative Technology</h5>
                  <p>Endula Infra leverages cutting-edge technologies to pioneer innovative solutions and ensure precision in every aspect of its operations.</p>
                </Col>
              </Row>
            </Col>
            <Col lg={4} className='first-row-point-one' data-aos="fade-left">
              <Row>
                <Col lg={4} className='point-1-image'>
                  <div className='point-01'><h5>04</h5></div>
                  <img src="https://demo.xperthemes.com/constrox/wp-content/uploads/sites/2/2024/01/construction-industry-concept-150x150.jpg" alt="" />
                </Col>
                <Col lg={8}>
                  <h5>Trusted Reputation</h5>
                  <p>Renowned for its unwavering commitment to reliability, quality, and excellence, Endula Infra has earned a trusted reputation within the industry.</p>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className='justify-content-center  mt-5'>
            <Col lg={4} className='first-row-point-one  ' data-aos="fade-right">
              <Row>
                <Col lg={4} className='point-1-image'>
                  <div className='point-01'><h5>05</h5></div>
                  <img src="https://demo.xperthemes.com/constrox/wp-content/uploads/sites/2/2024/01/construction-industry-concept-150x150.jpg" alt="" />
                </Col>
                <Col lg={8}>
                  <h5>Customer-Centric Approach</h5>
                  <p>At Endula Infra, customer satisfaction is paramount. The company provides personalized support and tailored solutions to address the unique requirements of every client.</p>
                </Col>
              </Row>
            </Col>
            <Col lg={4} className='first-row-point-one ms-5' data-aos="fade-left">
              <Row>
                <Col lg={4} className='point-1-image'>
                  <div className='point-01'><h5>06</h5></div>
                  <img src="https://demo.xperthemes.com/constrox/wp-content/uploads/sites/2/2024/01/construction-industry-concept-150x150.jpg" alt="" />
                </Col>
                <Col lg={8}>
                  <h5>Vertical Integration</h5>
                  <p>Endula Infra boasts in-house production of raw materials, ensuring stringent quality control measures and timely deliveries to clients.</p>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className='justify-content-center first-row mt-5'>
            <Col lg={4} className='first-row-point-one me-3' data-aos="fade-right">
              <Row>
                <Col lg={4} className='point-1-image'>
                  <div className='point-01'><h5>07</h5></div>
                  <img src="https://demo.xperthemes.com/constrox/wp-content/uploads/sites/2/2024/01/construction-industry-concept-150x150.jpg" alt="" />
                </Col>
                <Col lg={8}>
                  <h5>Sustainability Focus</h5>
                  <p>Committed to environmental stewardship, Endula Infra prioritizes sustainable practices throughout its operations, minimizing its ecological footprint.</p>
                </Col>
              </Row>
            </Col>
            <Col lg={4} className='first-row-point-one' data-aos="fade-left">
              <Row>
                <Col lg={4} className='point-1-image'>
                  <div className='point-01'><h5>08</h5></div>
                  <img src="https://demo.xperthemes.com/constrox/wp-content/uploads/sites/2/2024/01/construction-industry-concept-150x150.jpg" alt="" />
                </Col>
                <Col lg={8}>
                  <h5>Timely Project Completion</h5>
                  <p>Endula Infra streamlines its processes to ensure the timely delivery of projects, facilitating seamless project execution and client satisfaction.</p>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}
