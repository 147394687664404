import React, { useEffect, useRef, useState } from 'react';
import 'aos/dist/aos.css';
import AOS from 'aos';
import Videoframe from '../images/videoframe_436.png'
import '../Css/plast.css'
import { Col, Container, Row } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
export default function Plast() {
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef(null);

  useEffect(() => {
    AOS.init({
      duration: 1000,
    });

    const handlePlay = () => {
      setIsPlaying(true);
    };

    const handlePause = () => {
      setIsPlaying(false);
    };

    const handleEnded = () => {
      setIsPlaying(false);
    };

    const videoElement = videoRef.current;

    videoElement.addEventListener('play', handlePlay);
    videoElement.addEventListener('pause', handlePause);
    videoElement.addEventListener('ended', handleEnded);

    return () => {
      videoElement.removeEventListener('play', handlePlay);
      videoElement.removeEventListener('pause', handlePause);
      videoElement.removeEventListener('ended', handleEnded);
    };
  }, []);

  const handleVideoPlayPause = () => {
    const videoElement = videoRef.current;
    if (videoElement.paused) {
      videoElement.play();
    } else {
      videoElement.pause();
    }
  };
  return (
    <div>
      <div className='Plast-heading-banner'>
        <h1 className='container'> Plast</h1>
      </div>
      <Container>
        <Row className='plast-content'>
          <Col lg={6}>
            <p>Sand Plast, a revolutionary solution for brick working and plastering applications in construction. Sand Plast blends premium sand aggregates with advanced additives, carefully manufactured to improve workability, adhesion, and finish quality in plastering tasks. Our unwavering commitment to excellence motivates us to offer construction experts a superior plastering material that surpasses industry standards.</p>

          </Col>
          <Col lg={6}>
            <img src="https://www.shutterstock.com/shutterstock/photos/58010176/display_1500/stock-photo-home-improvement-58010176.jpg" alt="" />
          </Col>
        </Row>
      </Container>
      <section className="HomeVideoSection relative mt-5 mb-4">
        <div className="HomeVideoOuter">
          <div className="wrapper">
            <div className="HomeVideoInner">
              <div className="Video-playbtn" onClick={handleVideoPlayPause}>
                <svg
                  id="Component_16_9"
                  data-name="Component 16 – 9"
                  xmlns="http://www.w3.org/2000/svg"
                  width="15"
                  height="17"
                  viewBox="0 0 15 17"
                >
                  <path
                    id="Polygon_2"
                    data-name="Polygon 2"
                    d="M8.5,0,17,15H0Z"
                    transform="translate(15) rotate(90)"
                    fill="#fff"
                  ></path>
                </svg>
              </div>
              <div className="StoryVideo">
                <video
                  id="rightVideo"
                  ref={videoRef}
                  playsInline
                  controls
                  poster={Videoframe}
                >
                  <source
                    type="video/mp4"
                    src="https://www.shutterstock.com/shutterstock/videos/1072036603/preview/stock-footage-close-up-tile-adhesive-is-mixed-using-an-electric-drill-in-a-bucket.webm"
                  />
                </video>
              </div>
              <div
                className={`HomeVideoTitleSection ${isPlaying ? 'animate_it' : 'animate_reverse'}`}
              >
                <div className="SectionTitle">
                  <h2>
                    An innovative approach for brick work and plastering work.
                  </h2>
                </div>
                <div className="SectionContent">
                  <p>Sand Plast simplifies the plastering process, ensuring reliability and effectiveness. Its exceptional bonding properties and uniform particle distribution guarantee a flawless finish on all surfaces, whether it's interior walls, ceilings, or exterior facades. Sand Plast not only enhances aesthetics but also enhances the durability of any structure, adding significant value to construction projects.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className='service-bottom'>
        <h2 className='text-center mt-5 advantages-heading'>Advantages of Sand Plast</h2>
        <hr className="grunge-underline"></hr>
        <Container fluid className='Accordion-advantages'>
          <Row>
            <Col lg={6}> <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>Enhanced Workability</Accordion.Header>
                <Accordion.Body>
                Sand Plast offers excellent workability, allowing for easy application and shaping during plastering.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>Superior Adhesion</Accordion.Header>
                <Accordion.Body>
                Our advanced additives promote strong adhesion to various substrates, ensuring long-lasting plaster bonds.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion></Col>
            <Col lg={6}> <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>Uniform Finish</Accordion.Header>
                <Accordion.Body>
                Sand Plast consistent particle size distribution results in a smooth and uniform finish, enhancing the overall appearance of plastered surfaces.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>Reduced Cracking</Accordion.Header>
                <Accordion.Body>
                The special formulation of SandPlast helps minimize shrinkage and cracking, improving the structural integrity of plastered surfaces.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion></Col>
          </Row>
        </Container>
      </div>
    </div>
  )
}
