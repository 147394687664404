import React, { useState, useEffect } from 'react';
import "../Css/contact.css";
import Container from 'react-bootstrap/Container';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faLocationDot, faPhone } from '@fortawesome/free-solid-svg-icons';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Button from 'react-bootstrap/Button';
import { CardBody, CardSubtitle, CardText, CardTitle } from 'react-bootstrap';
import AOS from 'aos';
import 'aos/dist/aos.css';
export default function ContactUs() {
    const [validated, setValidated] = useState(false);

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }

        setValidated(true);
    };
    useEffect(() => {
        AOS.init({
            duration: 1000,
        });
    }, []);
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    return (
        <div>
            <div className="contact-heading-banner">
                <h2 className='container'>Contact Us</h2>
            </div>
            <div>
                <Container>
                    <Row className='mt-5 mb-5 g-md-5 g-5 mx-auto contact-us-boxes'>
                        <Col lg={4}>
                            <Card className='text-center' data-aos="zoom-in-down">
                                <Card.Body>
                                    <Card.Title><FontAwesomeIcon icon={faLocationDot} /></Card.Title>
                                    <Card.Subtitle className="mb-2 text-muted">Location</Card.Subtitle>
                                    <Card.Text>
                                        ENDULA INFRA LLP, JF8M+P86, Medchal, Secunderabad, Telangana 501401
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={4}>
                            <Card className='text-center' data-aos="zoom-in-down">
                                <Card.Body>
                                    <Card.Title><FontAwesomeIcon icon={faPhone} /></Card.Title>
                                    <Card.Subtitle className="mb-2 text-muted">Contacts</Card.Subtitle>
                                    <Card.Text>
                                        +91 9876543210<br />
                                        +91 9876543210
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={4}>
                            <Card className='text-center' data-aos="zoom-in-down">
                                <Card.Body>
                                    <Card.Title><FontAwesomeIcon icon={faEnvelope} /></Card.Title>
                                    <Card.Subtitle className="mb-2 text-muted">Emails</Card.Subtitle>
                                    <Card.Text>
                                        endulainfra@gmail.com<br />
                                        infra@endula.com
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className='contact-us-section'>
                <Container>
                    <Row className='pt-5 pb-5'>
                        <Col lg={6}>
                            <Card data-aos="fade-right">
                                <CardBody>
                                    <CardTitle>
                                        <h6>Get in Touch</h6>
                                    </CardTitle>
                                    <CardSubtitle><h3>Make An Free Consultant</h3></CardSubtitle>
                                    <CardText>
                                        <Form noValidate validated={validated} onSubmit={handleSubmit} >
                                            <Row className="mb-5 mt-3 g-5 g-md-4" >
                                                <Col lg={6}>
                                                    <Form.Group >
                                                        <Form.Control type="text" placeholder="Name *" required />
                                                        <Form.Control.Feedback type="invalid">
                                                            Please provide a valid Name.
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Col>
                                                <Col lg={6}>
                                                    <Form.Group controlId="formGroupEmail">
                                                        <Form.Control type="email" placeholder="Enter email *" required />
                                                        <Form.Control.Feedback type="invalid">
                                                            Please provide a valid email.
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <FloatingLabel controlId="floatingTextarea2" label="Message *">
                                                <Form.Control
                                                    as="textarea"
                                                    placeholder="Leave a comment here"
                                                    style={{ height: '100px' }}
                                                    required
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Please provide a message .
                                                </Form.Control.Feedback>
                                            </FloatingLabel>
                                            <Button as="input" type="submit" value="Send Message" className='mt-5' />
                                        </Form>
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg={6} data-aos="fade-up"
                            data-aos-anchor-placement="center-bottom">
                            <img src="https://www.shutterstock.com/shutterstock/photos/1757466731/display_1500/stock-photo-construction-worker-showing-smartphone-blank-screen-pointing-finger-recommending-building-services-1757466731.jpg" />
                        </Col>            
                    </Row>
                </Container>
            </div>
        </div>
    )
}




